.select-group {
  display: inline-block;
  padding: 12px;
}
.select-group-content .ant-select {
  display: inline-block;
  width: 100%;
}
.select-group-column-container {
  display: flex;
  .select-group-column {
    display: flex;
    flex: 1;
    align-items: center;
    margin-right: 0;
    padding: 12px;
    .select-group-title {
      white-space: nowrap;
    }
    .select-group-content {
      flex: 1;
      margin-left: 15px;
    }
  }
}
