.gantt-bar {
  .b-gantt-task {
    background-color: #9fd9f6;
  }
  .b-gantt-task-parent .b-gantt-task:not(.b-milestone) {
    background-color: #3299d9;
  }

  .b-grid-row.b-selected {
    background-color: rgba(50, 153, 217, 0.25);
  }

  .b-gantt-task.b-task-selected,
  .b-gantt-task:hover {
    background-color: #1a4f99;
  }

  .b-gantt-task-wrap.b-milestone-wrap .b-gantt-task:not(.b-gantt-task-withicon) .b-gantt-task-content {
    background-color: #fad860 !important;
  }
}

.b-grid-header .b-sch-timerange.b-sch-line {
  background-color: #fad860 !important;
}

.b-gridbase:not(.b-moving-splitter) .b-grid-subgrid:not(.b-timeaxissubgrid) .b-grid-row:not(.b-group-row).b-hover {
  background-color: rgba(50, 153, 217, 0.1) !important;
}

.b-gridbase:not(.b-moving-splitter) .b-grid-subgrid:not(.b-timeaxissubgrid) .b-grid-row:not(.b-group-row).b-hover.b-selected {
  background-color: rgba(50, 153, 217, 0.4) !important;
}

.b-gantt > .b-toolbar {
  border-bottom: 1px solid;
}
.b-gantt > .b-toolbar > .b-content-element > .b-widget:not(.b-last-visible-child) {
  margin-inline-end: 1em;
}
.b-gantt > .b-toolbar > .b-content-element .b-button {
  min-width: 3.5em;
}

.filter-by-name label {
  display: none;
}

.b-theme-stockholm .b-gantt > .b-toolbar {
  border-bottom-color: #d8d9da;
}

.b-theme-classic .b-gantt > .b-toolbar {
  background-color: #f1f1f4;
  border-bottom-color: #b0b0b6;
}
.b-theme-classic .b-gantt > .b-toolbar .b-has-label label {
  color: #555;
}

.b-theme-classic-light .b-gantt > .b-toolbar {
  background-color: #fff;
  border-bottom-color: #e0e0e0;
}

.b-theme-material .b-gantt > .b-toolbar {
  background-color: #fff;
  border-bottom: none;
}
.b-theme-material .b-gantt > .b-toolbar .filter-by-name label {
  display: block;
}

.b-theme-classic-dark .b-gantt > .b-toolbar {
  background-color: #2b2b2f;
  border-bottom-color: #2a2b2e;
}

.b-status-column-cell {
  font-size: 0.8em;
}

.b-status-column-cell i::before {
  margin-inline-end: 0.5em;
}

.Started {
  color: #bbb;
}

.Late {
  color: #ff5722;
}

.Completed {
  color: #34d844;
}

.settings-menu .b-menu-content {
  padding: 0.5em;
}
.settings-menu .b-slider {
  width: 12em;
  margin-bottom: 0.5em;
}

.b-sch-highlighted-range {
  color: black;
  background: #ff00002e;
  border: 2px dashed #ff000056;
}

.buffer-task.b-gantt-task {
  background-color: #ff4d4f;
}

.b-gantt-task-wrap {
  .milestone.b-gantt-task {
    overflow: visible;
    & > div {
      display: flex;
      align-items: center;
      transition: background-color 0.2s, border 0.2s;
      background-color: #fff;
      overflow: visible;
      &::before {
        position: absolute;
        left: 0.1464465em;
        content: ' ';
        height: 0.707107em;
        width: 0.707107em;
        transform-origin: 50% 50%;
        transform: rotate(45deg);
        background-color: #83b5fe;
      }
    }
  }
}
