.memo-container {
  & > div {
    height: 100%;
  }
  .ant-modal-content,
  .effimate-modal-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0;
    .ant-modal-header,
    .effimate-modal-header {
      padding: 16px 24px 8px;
      color: #373737;
      background: #fff;
      border-bottom: 1px solid rgba(0, 0, 0, 0.06);
      border-radius: 2px 2px 0 0;
      margin-bottom: 0;
      .ant-modal-title,
      .effimate-modal-title {
        span {
          line-height: 22px;
          font-size: 20px;
        }
        .ant-tag,
        .effimate-tag {
          font-size: 12px;
        }
      }
    }
    .ant-modal-body,
    .effimate-modal-body {
      height: calc(100% - 76px);
      padding: 0;
      .ant-spin-nested-loading,
      .effimate-spin-nested-loading {
        height: 100%;
        .ant-spin-container,
        .effimate-spin-container {
          height: 100%;
          .ChatApp {
            background: #fff;
            .Composer-inputWrap {
              .Composer-input {
                caret-color: #1a4f99;
              }
            }
            .Message-inner {
              display: flex;
              flex-direction: column;
              padding: 0 12px;
              .Message-content {
                flex-direction: row;
                & > div {
                  width: 100%;
                }
                .bubble-not-self {
                  .Bubble {
                    background: rgba(176, 206, 219, 0.6);
                  }
                }
                .Bubble {
                  width: calc(100% - 50px);
                  margin-right: 10px;
                  padding: 6px;
                  background: rgba(250, 216, 96, 0.6);
                  border-radius: 6px;

                  .extra {
                    margin-top: 5px;
                    color: grey;
                    font-size: smaller;
                    font-style: italic;
                    line-height: 105%;
                  }
                }
              }
            }
            .Composer-sendBtn {
              background: #3299d9;
            }
          }
        }
      }
    }
  }
}
