.aipskd {
  .schedule .ant-picker-input {
    width: 100px;
  }

  .view-detail-icon {
    position: relative;
    top: 2px;
    font-size: 24px;
  }

  .taskListWrapper {
    display: table;
    border-bottom: #e6e4e4 1px solid;
    border-left: #e6e4e4 1px solid;
  }

  .taskListTableRow {
    display: table-row;
    text-overflow: ellipsis;
  }

  .taskListTableRow:nth-of-type(even) {
    background-color: #f5f5f5;
  }

  .taskListCell {
    display: table-cell;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    vertical-align: middle;
  }
  .taskListNameWrapper {
    display: flex;
  }

  .taskListExpander {
    padding: 0.15rem 0.2rem 0rem 0.2rem;
    color: rgb(86 86 86);
    font-size: 0.6rem;
    cursor: pointer;
    user-select: none;
  }
  .taskListEmptyExpander {
    padding-left: 1rem;
    font-size: 0.6rem;
    user-select: none;
  }

  .ganttTable {
    display: table;
    border-top: #e6e4e4 1px solid;
    border-bottom: #e6e4e4 1px solid;
    border-left: #e6e4e4 1px solid;
  }

  .ganttTable_Header {
    display: table-row;
    list-style: none;
  }

  .ganttTable_HeaderSeparator {
    margin-left: -2px;
    border-right: 1px solid rgb(196, 196, 196);
    opacity: 1;
  }

  .ganttTable_HeaderItem {
    display: table-cell;
    vertical-align: -webkit-baseline-middle;
    vertical-align: middle;
  }

  .schedule-card .ant-card-grid {
    padding: 12px 24px;
  }

  .schedule-card-body .ant-card-body,
  .schedule-card-body .effimate-card-body {
    padding: 12px 24px;
  }

  .list-card .ant-card-body,
  .list-card .effimate-card-body {
    padding: 8px;
  }

  .ant-timeline-item {
    padding-bottom: 6px;
  }

  .ant-timeline-item-last {
    min-height: 0;
  }

  .list-card-header {
    position: relative;
    padding-bottom: 6px;
  }

  .list-card-title {
    display: inline;
    margin-bottom: 16px;
    font-weight: bold;
    font-size: 20px;
  }

  .list-card-subtitle {
    top: 4px;
    font-size: 14px;
    .ant-btn {
      margin-left: 1px;
      border-radius: 0;
    }
  }

  .list-card-content {
    .list-card-content-item {
      position: relative;
      margin: 0 12px;
    }
    .list-card-content-item.disabled * {
      color: lightgray !important;
    }
  }

  .float-right {
    position: absolute;
    right: 0;
  }

  .list-card .ant-card-head-title,
  .list-card .effimate-card-head-title {
    padding: 6px 16px;
  }

  .list-card .ant-card-extra,
  .list-card .effimate-card-extra {
    padding: 6px 0;
  }

  .card-item.border {
    border-left: 1px solid rgba(0, 0, 0, 0.06);
  }

  .border-bottom {
    padding: 4px 24px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  }

  .card-item {
    height: 100%;
    padding: 18px 24px;
  }

  .card-scroll-item {
    display: block;
    float: left;
  }

  .card-scroll-item.border {
    border-left: 1px solid rgba(0, 0, 0, 0.06);
  }

  .card-outer-container {
    overflow: hidden;
  }

  .card-scroll-container {
    height: 100%;
    overflow: hidden;
  }

  .flex-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .flex-column-align-center {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .scroll-action {
    z-index: 10;
    display: inline-block;
    width: 40px;
    height: 100%;
    text-align: center;
    .ant-btn,
    .effimate-btn {
      padding: 0;
    }
    .anticon {
      font-size: 40px;
    }
    &:first-child {
      margin-right: -40px;
    }
    &:last-child {
      margin-left: -40px;
    }
  }

  .scroll-context {
    display: inline-block;
    width: 100%;
  }

  .card-container {
    height: 100%;
    overflow: hidden;
  }

  .timeline-item {
    padding: 20px 40px;
    .ant-slider {
      margin-top: 26px;
    }
    .ant-slider-handle {
      display: none;
    }
    .mark-title {
      font-weight: bold;
      font-size: 14px;
    }
    .mark-content {
      font-size: 12px;
    }
  }

  .action-link {
    padding: 0;
    font-size: 12px;
  }

  .strategy-name {
    display: inline-block;
    height: 32px;
    font-weight: bold;
    line-height: 32px;
  }

  .card.border {
    border: 1px solid rgba(0, 0, 0, 0.06);
  }

  .alert-task .ant-menu-item-group-title {
    color: #ff0000;
  }

  .alert-sider {
    position: absolute;
    top: 1px;
    right: 0;
    z-index: 99;
    height: calc(100% - 2px);
    margin-right: 12px;
    background: #fff;
    .alert-menu {
      height: calc(100vh - 150px);
      overflow-x: hidden;
      overflow-y: scroll;
    }
    .ant-tree-title {
      display: inline-block;
      width: 100%;
    }
    .title-text {
      display: inline-block;
      width: calc(100% - 64px);
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .ant-menu-title-content {
      padding-left: 24px !important;
    }
    .menu-header {
      height: 46px;
      line-height: 46px;
      border-bottom: 1px solid #eee;
      display: flex;
      justify-content: space-between;
      .menu-title {
        display: inline-block;
        padding: 0 0 0 24px;
        overflow: hidden;
        font-weight: 900;
        font-size: 20px;
        white-space: nowrap;
        text-align: left;
        text-overflow: ellipsis;
      }
      .close-icon {
        padding: 0 12px;
        .anticon {
          padding: 0 6px;
        }
      }
    }
    .ant-tree .ant-tree-treenode {
      padding: 6px;
    }
    .title-action {
      float: right;
      .anticon {
        padding: 0 6px;
      }
      & > span {
        padding: 0 13px;
      }
    }
  }

  .chart-y-label {
    float: left;
    height: 130px;
    line-height: 130px;
  }

  .chart-x-label {
    width: 130px;
    text-align: center;
  }

  .chart-container {
    float: left;
  }

  .chart-table-item {
    flex: 1;
    font-weight: bolder;
    &:first-child {
      flex: none;
      align-items: center;
      width: 30px;
      .number-icon {
        display: block;
        width: 15px;
        height: 15px;
        margin: 6px auto 0;
        margin-top: 6px;
        color: #fff;
        font-size: 10px;
        line-height: 15px;
        text-align: center;
        background-color: #000;
        border-radius: 50%;
      }
    }
  }

  .list-card-table {
    padding-top: 5px;
  }
  .chart-table {
    display: flex;
    justify-content: center;
    height: 23px;
    margin-left: 0px;
    padding: 1px 2px;
    font-size: 12px;
    text-align: center;
    border: 1px solid rgba(0, 0, 0, 0.06);
    &:first-child {
      margin-top: 3px;
    }
    &.header {
      background-color: #fafafa;
    }
  }

  .list-card-header {
    position: relative;
  }

  .list-card-title {
    display: inline;
    margin-bottom: 16px;
    color: #555;
    font-weight: bold;
    font-size: 18px;
  }

  .list-card-subtitle {
    top: 4px;
    font-size: 14px;
    .ant-btn {
      margin-left: 1px;
      border-radius: 0;
    }
  }

  .scroll-content {
    overflow-y: scroll;
  }

  .filter-radio {
    .ant-radio-button-wrapper {
      min-width: 80px;
      text-align: center;
    }
  }

  .summary-kpi-card {
    height: 100%;
    padding: 10px 12px;
    min-height: 160px;
  }

  .summary-kpi-modal {
    .chart-table-item {
      width: 120px;
      font-size: 18px;
    }
  }

  .ant-table-cell-scrollbar {
    display: none;
  }

  .text-center {
    text-align: center !important;
  }

  .tree-table {
    .ant-table-row-level-0 {
      td:first-child {
        border-top: 1px solid rgba(0, 0, 0, 0.06);
        border-bottom: none;
      }
    }
    .ant-table-row-level-1 {
      td:first-child {
        border-bottom: none;
      }
      &:last-child {
        td:first-child {
          border-bottom: 1px solid rgba(0, 0, 0, 0.06);
        }
      }
    }
    .ant-table-row-indent + .ant-table-row-expand-icon {
      margin-top: 6.5px;
    }
  }

  .site-tree-search-value {
    color: #f50;
  }

  .icon-disabled {
    color: rgba(0, 0, 0, 0.25);
  }

  .form-container {
    display: flex;
    align-items: flex-end;
    .form-item-vertical {
      display: flex;
      flex-direction: column;
      margin-left: 15px;
      label {
        font-size: 12px;
      }
    }
  }

  .ant-tag-success {
    color: #52c41a;
    background: #f6ffed;
    border-color: #b7eb8f;
  }

  .ant-tag-warning {
    color: #faad14;
    background: #fffbe6;
    border-color: #ffe58f;
  }

  .ant-tag-error {
    color: #ff4d4f;
    background: #fff2f0;
    border-color: #ffccc7;
  }

  .select-height-32 {
    .ant-select-selector {
      height: 32px;
    }
  }
}

.ant-select-selection-overflow,
.effimate-select-selection-overflow {
  flex-wrap: nowrap !important;
}

.link-ellipsis {
  width: 100%;
  span {
    width: 100%;
    overflow: hidden;
    text-align: left;
    text-overflow: ellipsis;
  }
  &.plan {
    width: unset;
    max-width: calc(100% - 40px);
  }

  &.disabled {
    color: #373737;
    cursor: default;
  }
}
