.drawer-sider {
  .ChatApp {
    background: white;
  }

  .Navbar {
    background: white;
  }

  .Navbar-main-cus {
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 2px solid rgba(0, 0, 0, 0.08);
  }

  .Navbar-title {
    width: 250px;
    overflow: hidden;
    font-weight: bold;
    font-size: 14px;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .ant-spin-nested-loading,
  .effimate-spin-nested-loading {
    height: 100%;
  }

  .ant-spin-container,
  .effimate-spin-container {
    height: 100%;
  }

  .ant-spin-container.ant-spin-blur,
  .effimate-spin-container.effimate-spin-blur {
    height: 100%;
  }

  .ant-spin.ant-spin-spinning,
  .effimate-spin.ant-spin-spinning {
    max-height: fit-content;
  }

  .Composer-input {
    caret-color: #1a4f99;
  }

  .Btn.Btn--primary.Composer-sendBtn {
    background: #3299d9;
  }

  .Input.Input--outline.Composer-input {
    font-size: small;
  }

  .Message.right .Bubble {
    background: rgba(250, 216, 96, 0.6);
  }

  .Message.left .Bubble {
    background: rgba(176, 206, 219, 0.6);
  }

  .Message.right .ant-popover-open {
    background: rgba(250, 216, 96, 0.6);
    p {
      margin-bottom: 0;
    }
  }

  .Bubble span.time {
    color: #808080;
    font-style: italic;
  }

  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 5px;
  }

  ::-webkit-scrollbar-track {
    border-radius: 5px;
  }
}
