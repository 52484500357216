body {
  background-color: #f0f2f5;
}

.logo {
  height: 42px;
  margin: 16px;
}

.nav-logo {
  float: left;
  font-weight: 700;
  font-size: 24px;
}

.site-layout .site-layout-background {
  background: #fff;
}

.scm-main header {
  height: 50px;
  line-height: 50px;
}

.header-sider {
  width: 100% !important;
  max-width: inherit !important;
  height: 50px;
  padding: 0 20px;
  line-height: 50px;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 8px;
  height: 7px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 5px;
}

.ant-table-body,
.effimate-table-body {
  scrollbar-color: auto;
}

.ant-table-body::-webkit-scrollbar,
.effimate-table-body::-webkit-scrollbar {
  width: 8px;
  height: 7px;
}
.ant-table-body::-webkit-scrollbar-thumb,
.effimate-table-body::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 5px;
}

.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ant-layout-footer,
.effimate-layout-footer {
  padding: 8px;
}

.user-action {
  padding-right: 120px;
}

.loading-page {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.loading-cover {
  position: absolute;
  z-index: 200;
  background: #eeeeee;
  opacity: 0.5;
}

.ant-menu-dark .ant-menu-inline.ant-menu-sub,
.effimate-menu-dark .effimate-menu-inline.effimate-menu-sub {
  background: #1a4f99;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected,
.effimate-menu-dark.effimate-menu-dark:not(.effimate-menu-horizontal) .effimate-menu-item-selected {
  background: #619bc5;
}

/* Flex */
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-justify-center {
  display: flex;
  justify-content: center;
}

.flex-justify-between {
  display: flex;
  justify-content: space-between;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.check-group-row {
  .ant-checkbox-group-item,
  .effimate-checkbox-group-item {
    width: 100%;
  }
}

.ant-transfer,
.effimate-transfer {
  justify-content: center;
}

.line-clamp {
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; /* 控制行数 */
}

.font-size-12 {
  font-size: 12px;
}

.button-link-underline {
  span {
    text-decoration: underline;
  }
}

.mode-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.view-mode {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 46px;
  height: 32px;
  margin-right: 15px;
  cursor: pointer;
}

.ant-table-cell:has(.highLight),
.effimate-table-cell:has(.highLight) {
  background: rgba(255, 0, 0, 0.3);
  animation: bg-blink 1s linear forwards;
}

.ant-modal-content,
.effimate-modal-content {
  .virtual-grid {
    &.super-small {
      .virtual-table-cell,
      .effimate-table-cell {
        height: 28px;
      }
      .effimate-table-placeholder {
        .virtual-table-cell,
        .effimate-table-cell {
          height: auto;
        }
      }
    }
    .virtual-table-cell,
    .effimate-table-cell {
      padding-top: 3px;
      padding-left: 8px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      border-right: 1px solid #eeeeee;
      border-left: 1px solid #eeeeee;
    }

    .virtual-table-row.virtual-table-cell-even,
    .effimate-table-row.virtual-table-cell-even {
      background-color: #f5f5f5af;
    }

    .virtual-table-row.virtual-table-cell-odd,
    .effimate-table-row.virtual-table-cell-odd {
      background-color: #ffffff;
    }
  }

  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background: #bcbcbc;
    border-radius: 5px;
  }

  ::-webkit-scrollbar-track {
    border-radius: 5px;
  }
}

.select-height-32 {
  .ant-select-selector,
  .effimate-select-selector {
    height: 32px;
  }
}

.ant-table-cell-scrollbar,
.effimate-table-cell-scrollbar {
  display: none;
}

.row-dragging {
  &.light-green {
    background-color: rgb(238, 252, 238);

    > td.ant-table-cell-fix-left,
    > td.ant-table-cell-fix-right,
    > td.ant-table-cell-row-hover,
    > td.effimate-table-cell-fix-left,
    > td.effimate-table-cell-fix-right,
    > td.effimate-table-cell-row-hover {
      background-color: rgb(238, 252, 238);
    }
  }

  &.light-red {
    background-color: rgb(255, 228, 228);

    > td.ant-table-cell-fix-left,
    > td.ant-table-cell-fix-right,
    > td.ant-table-cell-row-hover,
    > td.effimate-table-cell-fix-left,
    > td.effimate-table-cell-fix-right,
    > td.effimate-table-cell-row-hover {
      background-color: rgb(255, 228, 228);
    }
  }
}

.project-setup-form {
  .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before,
  .effimate-table-thead
    > tr
    > th:not(:last-child):not(.effimate-table-selection-column):not(.effimate-table-row-expand-icon-cell):not([colspan])::before {
    width: 0;
  }

  .ant-table-cell,
  .effimate-table-cell {
    border-color: #ddd;
    border-right: solid 1px #ddd;
    border-bottom: solid 1px #ddd;

    &:first-child {
      border-left: solid 1px #ddd;
    }
  }

  th.ant-table-cell,
  th.effimate-table-cell {
    border-top: solid 1px #ddd;
  }

  .ant-table.ant-table-small .ant-table-thead > tr > th,
  .effimate-table.effimate-table-small .effimate-table-thead > tr > th {
    padding: 4px;
  }

  .ant-table-thead > tr:not(:nth-child(1)),
  .effimate-table-thead > tr:not(:nth-child(1)) {
    th {
      border-left: 0;
    }
  }

  .project-select {
    width: 100%;

    &.left {
      .ant-select-selector,
      .effimate-select-selector {
        text-align: left;
      }
    }

    &.right {
      .ant-select-selector,
      .effimate-select-selector {
        text-align: right;
      }
    }
  }
}

.pagination-style-initial {
  .ant-table-pagination,
  .effimate-table-pagination {
    position: initial !important;
    display: flex !important;
  }
}

.position-relative {
  position: relative;
}

.ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background: #a754b5;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: #c061c5;
}

.text-right {
  text-align: right;
}

.plan-form {
  .ant-form-item-explain,
  .effimate-form-item-explain {
    min-width: 250px;
  }
}

.date-picker-control {
  .effimate-picker-cell {
    padding: 0;
    position: relative;
    z-index: 0;
    .cell-disabled {
      position: relative;
      z-index: 100;
      background: rgba(0, 0, 0, 0.04);
      padding: 6px 0;
      width: 36px;
      height: 36px;
      .effimate-picker-cell-inner {
        color: rgba(0, 0, 0, 0.25);
      }
    }
  }
}
