.ant-table-row-expand-icon,
.effimate-table-row-expand-icon {
  color: var(--ant-primary-color);
  text-decoration: none;
  cursor: pointer;
  transition: color 0.3s;
  position: relative;
  display: inline-flex;
  float: left;
  box-sizing: border-box;
  width: 17px;
  height: 17px;
  padding: 0;
  color: inherit;
  line-height: 17px;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 2px;
  outline: none;
  transform: scale(0.94117647);
  transition: all 0.3s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.ant-table-row-expand-icon:after,
.ant-table-row-expand-icon:before,
.effimate-table-row-expand-icon:after,
.effimate-table-row-expand-icon:before {
  position: absolute;
  background: currentcolor;
  transition: transform 0.3s ease-out;
  content: "";
}
.ant-table-row-expand-icon:before,
.effimate-table-row-expand-icon:before {
  top: 7px;
  right: 3px;
  left: 3px;
  height: 1px;
}
.ant-table-row-expand-icon:after,
.effimate-table-row-expand-icon:after {
  top: 3px;
  bottom: 3px;
  left: 7px;
  width: 1px;
  transform: rotate(90deg);
}
.ant-table-row-expand-icon-collapsed:before,
.effimate-table-row-expand-icon-collapsed:before {
  transform: rotate(-180deg);
}
.ant-table-row-expand-icon-collapsed:after,
.effimate-table-row-expand-icon-collapsed:after {
  transform: rotate(0deg);
}
